<template>
  <div>
    <!--startprint-->
    <div class="centent">

      <div class="ewm">
        <img :src="xxInfo.imageEwm">
        <p>扫码<br />查验<br />证明</p>
      </div>

      <div class="zhaopian"><img v-if="xxInfo.user" :src="xxInfo.user.USERPHOTO"></div>

      <img style="position:absolute;top:0%; left:0%;"
           src="../../../assets/images/logo.png">

      <div class="bac"
           id="img1">
        <img name="imgs"
             style="position:absolute; top:0; left:0px; z-index:-999;width: 700px;height: 200px;"
             src="../../../assets/images/bnk.png">

      </div>
      <table cellpadding="0"
             cellspacing="0"
             width="700"
             border="1"
             class="abbb"
             id="table1">
        <tbody>
          <tr>
            <td colspan="6"
                class="biaot" v-if="xxInfo.tClass">[{{ xxInfo.tClass.CLASS_YEAR }}]年度专业技术人员继续教育学时证明</td>
          </tr>
          <tr>
            <td width="158"
                height="48"
                class="ad">班级名称</td>
            <td v-if="xxInfo.tClass"
                colspan="4">{{ xxInfo.tClass.CLASS_NAME }}</td>
            <td width="99"
                rowspan="3">
              
              </td>
          </tr>

          <tr>
            <td height="50"
                class="ad">学员姓名</td>
            <td width="141"
                v-if="xxInfo.user">{{ xxInfo.user.REALNAME }}</td>
            <td width="120"
                class="ad">身份证号</td>
            <td colspan="2"
                v-if="xxInfo.user">{{ xxInfo.user.CARDNO }}</td>
          </tr>
          <tr>
            <td height="50"
                class="ad">学习形式</td>
            <td>网络教育</td>
            <td class="ad">完成学时</td>
            <td colspan="2">{{ xxInfo.totalCredit }}学时</td>
          </tr>

          <tr>
            <td height="49"
                class="ad">类别</td>
            <td colspan="3"
                class="ad">学习课目</td>
            <td width="97"
                class="ad">学时（分）</td>
            <td class="ad">考核结果</td>
          </tr>
          <!-- <c:forEach items="${creditList }"
                     var="credit"> -->

          <!-- </c:forEach> -->
          <tr v-if="xxInfo.length == 0">
            <td height="98"
                class="ad">无</td>
            <td colspan="3"
                class="aleft">
              无<br>
            </td>
            <td>无</td>
            <td>无</td>
          </tr>

          <tr v-else
              v-for="(item,idx) in xxInfo.creditList"
              :key="idx">
            <td height="49"
                class="ad">{{ item.CODE_NAME }}</td>
            <td colspan="3"
                class="aleft">
              {{ item.COURSE_NAME }}<br>
            </td>
            <td>{{ item.CREDIT }}</td>
            <td>合格</td>
          </tr>

          <tr>
            <td height="49"
                class="ad">主办单位</td>
            <td colspan="5">
              安徽冠成教育科技有限公司
            </td>
          </tr>
          <tr>
            <td height="220"
                colspan="6">
            </td>
          </tr>
        </tbody>
      </table>
      <p class="qian">签证单位意见（盖章）： </p>
      <p class="gai">该学员已修完上述全部课程，经考核合格，准予结业。</p>
      <p class="anhui"
         style="text-align:right;">安徽省专业技术人员继续教育基地<br>
        安徽冠成教育科技有限公司
      </p>
      <p class="nian" v-if="xxInfo.tClass"><span id="time">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ xxInfo.tClass.CLASS_YEAR }} 年 {{ xxInfo.month }} 月 {{ xxInfo.day }} 日
        </span></p>
      <!-- <img v-if="xxInfo.yearFlag == '01'"
           src="../../../assets/images/huananzhang.png"
           alt="章"
           class="zs_bone_right"
           style="right: 320px;" /> -->
      <img src="../../../assets/images/gz02.png">
    </div>
    <!--endprint-->
    <div style="width:100%;display: flex;justify-content: center;margin-top: 50px;"
         v-if="doPrintw">
      <div>
        <el-button style="font-size: 16px;width: 100px;height: 30px;" type="primary"
                 @click="doPrint">打印</el-button>

      <el-button style="font-size: 16px;width: 100px;height: 30px;" @click="goBack">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { toCreditZM } from '@/api/tzgg.js';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      imgs: require('../../../assets/images/bnk.png'),
      doPrintw: true,
      xxInfo: {}
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    goBack() {
      this.$router.back();
    },
    check() {
      var t = document.getElementById('table1');
      var m = document.getElementById('img1');

      m.width = t.width;
      m.height = t.offsetHeight;
      var mh = m.height;
      var num = Math.ceil(mh / 200);
      var html = '';
      var hcha = 200 * num - mh;
      var cha = hcha / (num - 1);
      console.log(mh, 'num');
      for (var i = 0; i < num; i++) {
        if (i == 0) {
          html +=
            '<img name="imgs" style="position:absolute; top:0px; left:0px; z-index:-999;width: 700px;height: 200px;" src="' +
            this.imgs +
            '"/>';
        } else {
          var top = 200 * i - cha * i;
          html +=
            '<img name="imgs" style="position:absolute; top:' +
            top +
            'px; left:0px; z-index:-999;width: 700px;height: 200px;" src=" ' +
            this.imgs +
            '"/>';
        }
      }
      m.innerHTML = html;
    },
    doPrint() {
      this.doPrintw = false;
      setTimeout(() => {
        window.print();
      }, 100);

      setTimeout(() => {
        this.doPrintw = true;
      }, 2000);
    },
    ReplaceAll(str, sptr, sptr1) {
      while (str.indexOf(sptr) >= 0) {
        str = str.replace(sptr, sptr1);
      }
      return str;
    },
    toCreditZM(classId, userId) {
      let data = {
        classId: classId,
        userId: userId
      };
      toCreditZM(data).then(res => {
        console.log(res.data);
        this.xxInfo = res.data.data;
        this.xxInfo.totalCredit = this.xxInfo.totalCredit.toFixed(1);
        this.xxInfo.length = this.xxInfo.creditList.length;
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.toCreditZM(this.$route.query.classId, this.$route.query.userId);
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
   
    setTimeout(() => {
      this.check();
    }, 300);
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
}
.centent {
  margin: 0 auto;
  width: 700px;
  position: relative;
  min-height: 620px;
}
.bac {
  width: 700px;
  position: absolute;
  top: 0;
  left: 0;
}
.centent img {
  position: absolute;
  bottom: 35px;
  right: 100px;
  z-index: -1;
}
.ewm {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 20px;
  left: 30px;
}
.ewm p {
  position: absolute;
  top: 20px;
  right: -25px;
  overflow: hidden;
  font-size: 14px;
  font-family: 楷体;
  font-weight: bold;
  color: #000000;
}
.ewm img {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 70px;
  height: 70px;
}
.ewm_box {
  position: absolute;
  bottom: 19px;
  left: 19px;
  width: 125px;
  height: 70px;
  border: 1px solid #bbb;
}
.zhaopian {
  width: 100px;
  height: 150px;
  position: absolute;
  top: 102px;
  right: 0;
}
.zhaopian img {
  width: 100px;
  height: 150px;
  position: absolute;
  top: 0;
  left: 0;
}
.qian {
  position: absolute;
  bottom: 155px;
  left: 5%;
}
.gai {
  position: absolute;
  bottom: 127px;
  left: 10%;
}
.anhui {
  position: absolute;
  bottom: 65px;
  right: 5%;
}
.nian {
  position: absolute;
  bottom: 37px;
  right: 5%;
}
.abbb {
  border-collapse: collapse;
  margin: 0 auto;
  position: relative;
}
.ad {
  font-weight: 900;
}
.aleft {
  align: left;
}
table tr td {
  text-align: center;
  border: 1px solid #666;
}
table tr th {
  height: 50px;
  border: 1px solid #333;
  color: #33c;
  background-color: #0cf;
}
.biaot {
  font-size: 24px;
  height: 100px;
  font-weight: 800;
}
</style>